import { requestToken, walletSignature } from "./axiosURL"
// 连接钱包后获取返回的token字段
// const WalletToken = sessionStorage.getItem('account')


export default function OverduToken() {
    requestToken({ bscAddress: window.injectTPAddress ?? localStorage.getItem('account') }).then(data => {
        console.log(data.data, 'token')
        setTimeout(() => {
            // 请求签名
            window.ethereum.request({
                method: "personal_sign",
                params: [window.injectTPAddress ?? localStorage.getItem('account'), data.data]
            }).then(res => {
                walletSignature({
                    code: data.data,
                    bscAddress: window.injectTPAddress ?? localStorage.getItem('account'),
                    signature: res
                }).then(datas => {
                    console.log(`${datas.data}, 请求返回`)
                    if (datas.data.length < 50) return
                    localStorage.setItem("userToken", datas.data)
                    window.location.reload()
                })
            }).catch(err => {
                console.log(err)
            })
        }, 100)
    }).catch(err => {
        console.log(err)
    })
}