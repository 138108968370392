import axios from 'axios';
import { Toast } from 'antd-mobile';
import OverduToken from '../utils/OverdueToken';

// 全局请求头
axios.defaults.headers.token = window.localStorage.getItem('userToken')

// 请求拦截器
axios.interceptors.request.use(function (config) {
    let token = localStorage.getItem('userToken')
    if (token) {
        config.headers.token = token
    }
    return config
}, function (error) {
    return Promise.reject(error);
});

// 响应拦截器
axios.interceptors.response.use(function (response) {
    
    if (response.data.code === 4000) {
        Toast.show({
            icon: 'fail',
            duration: 2000,
            content: response.data.data
        })
        if (
            response.data.data === "NFT处于质押中,不能再次质押"
            || response.data.data === "每次提现金额不得少于5"
            || response.data.data === "提现金额错误"
            || response.data.data === "每天只能提现一次"
            || response.data.data === "你的余额不足,无法提现"
            || response.data.data === "余额不足,请充值后再来质押" 
            || response.data.data === "质押成功"
            || response.data.data === "提现需要质押5000WKB的NFT才能提现"
            || response.data.data === "提现需要质押2000WKB的NFT才能提现"
            || response.data.data === "提现方式错误"
            || response.data.data === "该用户已被冻结"
        ) return 
        OverduToken()
    }
    return response
}, function (error) {
    // if (!error.response) {
        Toast.show({
            icon: 'fail',
            duration: 2000,
            content: '网络繁忙',
        })
    // }
})


async function createAxios(type, url, data) {
    if (type.toLocaleString() === 'get') {
        return await new Promise((res, rej) => {
            getAxios(url, data, res, rej)
        })
    } else if (type.toLocaleString() === 'delete') {
        return await new Promise((res, rej) => {
            deleteAxios(url, data, res, rej)
        })
    } else if (type.toLocaleString() === 'put') {
        return await new Promise((res, rej) => {
            putAxios(url, data, res, rej)
        })
    } else {
        return await new Promise((res, rej) => {
            postAxios(url, data, res, rej)
        })
    }
}


function getAxios(url, data = {}, res, rej) {
    axios.get(url, {
        params: data
    }).then(({ data }) => {
        res(data)
    }).catch(err => {
        rej(err)
    })
}

function postAxios(url, data = {}, res, rej) {
    axios.post(url, data).then(({ data }) => {
        res(data)
    }).catch(err => {
        rej(err)
    })
}

function deleteAxios(url, data = {}, res, rej) {
    axios.delete(url, {
        params: data
    }).then(({ data }) => {
        res(data)
    }).catch(err => {
        rej(err)
    })
}

function putAxios(url, data = {}, res, rej) {
    axios.put(url, data).then(({ data }) => {
        res(data)
    }).catch(err => {
        rej(err)
    })
}

export default createAxios
