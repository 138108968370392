import { configureStore } from "@reduxjs/toolkit";
import toggleLanguage from "../reducer/toggleLanguage";
import myArNum from "../reducer/myArNum";

const store = configureStore({
    reducer: {
        toggleLanguage,
        myArNum
    }
})

export default store