import React, { useState, useEffect } from "react";
import Style from "./index.module.scss";
import copy from 'copy-to-clipboard';
import { ReactComponent as Copy } from "../../assets/copy.svg";
import { Toast } from "antd-mobile";
import { requestMyTeam, directlyPeople, my } from "../../utils/axiosURL";
import useFlashback from '../../hooks/useFlashback'

const Index = () => {

    // 请求分页器的页码
    let [pageNum, setPageNum] = useState(1)
    // 请求数量
    // eslint-disable-next-line
    const [pageSize, setPageSize] = useState(10);
    // 存储所有团队记录总数
    const [teamAll, setTeamAll] = useState([])
    const [flashback] = useFlashback(teamAll);
    // 请求数量
    // eslint-disable-next-line
    const [dataNum, setDataNum] = useState(10)
    // 团队人数
    const [directlyNum, setDirectlyNum] = useState([])
    const [myShowData, setMyShowData] = useState({})

    useEffect(() => {
        my().then(({ data }) => {
            setMyShowData(data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    useEffect(() => {
        requestMyTeam({ pageNum, pageSize }).then(({ data }) => {
            setTeamAll(data)
        }).catch(err => {
            console.log(err);
        })
    }, [])

    useEffect(() => {
        directlyPeople().then(({ data }) => {
            setDirectlyNum(data)
        }).catch(err => {
            console.log(err)
        })
    }, [])
    // 地址格式处理
    const handleAddressFn = (value) => {
        if (!localStorage.getItem('account')) return
        let prev = value.slice(0, 4)
        let next = value.slice(value.length - 4, value.length)
        return [...prev, '...', ...next].join('')
    }
    // 复制地址
    const copyAddressFn = () => {
        if (copy(`${window.location.origin}/?${localStorage.getItem('account')}`)) {
            Toast.show({
                icon: 'success',
                duration: 2000,
                content: "复制成功",
            })
        } else {
            Toast.show({
                icon: 'fail',
                duration: 2000,
                content: "复制失败",
            })
        }
    };

    return (
        <div className={Style.HomePage}>
            <div className={Style.link}>
                <p className={Style.linkText}>邀请链接：{`${window.location.origin}/?${handleAddressFn(localStorage.getItem('account'))}`}</p>
                <div className={Style.copy} onClick={() => copyAddressFn()}>
                    <Copy />
                </div>
            </div>
            <div className={Style.tuijian}>
                <p>邀请总人数: {directlyNum.teamNumber}人</p>
                <p>直邀人数: {directlyNum.directNumber}人</p>
            </div>
            <div className={Style.linkListWrap}>
                <ul>
                    {
                        flashback?.slice((pageNum - 1) * dataNum, pageNum * 10).map((item, index) => {
                            return <li key={index}>
                                <span>{index + 1}、</span>
                                <span>{handleAddressFn(item.bscAddress)}</span>
                                <span className={Style.time}>( 时间: {item.registerTime} )</span>
                                <span className={Style.nft}>
                                    {
                                        item.nftIncome === 1 ? "-- 申领中"
                                        : item.nftIncome === 2 ? "-- NFT"
                                        : "-- 失败"
                                  }
                                </span>
                            </li>
                        })
                    }
                </ul>
                <div className={Style.Pagination}>
                    <button
                        onClick={() => {
                            if (pageNum <= 1) return
                            setPageNum(--pageNum)
                        }}
                    >上一页</button>
                    <p><span>{pageNum}</span>/<span>{Math.ceil(teamAll.length / pageSize)}</span></p>
                    <button
                        onClick={() => {
                            if (pageNum >= Math.ceil(teamAll.length / pageSize)) return
                            setPageNum(++pageNum)
                        }}>下一页</button>
                </div>
            </div>
        </div>
    )
}

export default Index;
