import { createSlice } from "@reduxjs/toolkit";

const toggleLanguage = createSlice({
    name: 'toggleLanguage',

    initialState: {
        language: false
    },

    reducers: {
        _toggleLanguage(state, action) {
            state.language = action.payload
        }
    }
})

export const { _toggleLanguage } = toggleLanguage.actions
export default toggleLanguage.reducer