import React from "react";
import Navbar from "../../components/Navbar/index";
import TopBar from "../../components/Topbar/index";
import { Outlet } from "react-router-dom";
import { useLocation } from "react-router-dom";
import wukong from "../../assets/WechatIMG136.jpeg"
import deveice from "../../assets/WechatIMG137.jpeg"
import mynft from "../../assets/WechatIMG25.jpeg"
import mine from "../../assets/WechatIMG26.jpeg"
import Style from "./index.module.scss";
import { useSelector } from "react-redux";

const Index = () => {

    const location = useLocation();
    const state = useSelector(state => state.toggleLanguage.language)

    return (
        <div className={Style.layout}>
            {
                location.pathname === "/"
                    ? <img className={Style.img} src={wukong} alt="" />
                    : location.pathname === '/tutorials'
                        ? <img className={Style.img} src={deveice} alt="" />
                        : location.pathname === '/mynft'
                            ? <img className={Style.img} src={mynft} alt="" />
                            : <img className={Style.img} src={mine} alt="" />
            }
            <TopBar />
            {
                state
                    ? <Navbar />
                    : <div className={Style.null}></div>
            }
            <div className={Style.outlet}>
                <Outlet />
            </div>
        </div>
    )
}

export default Index;
