import { createSlice } from "@reduxjs/toolkit";

const myArSlice = createSlice({
    name: "myArNum",

    initialState: {
        arNum: 0,
    },

    reducers: {
        _mineArNumFn(state, action) {
            state.arNum = action.payload
        }
    }
})

export const { _mineArNumFn } = myArSlice.actions;
export default myArSlice.reducer;