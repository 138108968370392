import React, { useState, useEffect } from "react";
import Style from "./index.module.scss";
import { walletVariation } from "../../utils/axiosURL";

const Index = () => {

    // 请求分页器的页码
    let [pageNum, setPageNum] = useState(1)
    // 请求数量
    const dataNum = 10
    // 存储所有收益记录数据
    const [allIncome, setAllIncome] = useState([])
    // 存储分页总数
    const [total, setTotal] = useState(0)

    // 收益记录
    useEffect(() => {
        walletVariation({ page: pageNum, pageSize: dataNum }).then(({ data }) => {
            setTotal(data.total)
            setAllIncome(data.records)
        }).catch(err => {
            console.log(err)
        })
    }, [pageNum])

    return (
        <div className={Style.HomePage}>
            <div className={Style.mask}></div>
            <div className={Style.xiangguanTitle}>
                收益记录相关
            </div>
            <ul className={Style.ul}>
                <li className={Style.li}>
                    <span className={Style.time}>时间</span>
                    <span className={Style.category}>类型</span>
                    <span className={Style.num}>数量</span>
                </li>
                {
                    allIncome?.map((item, index) => {
                        return <li className={Style.li} key={index}>
                            <span className={Style.time}>{item.createTime}</span>
                            <span className={Style.category}>{item.variationType}</span>
                            <span className={Style.num}>{item.variationUsdt} WKB</span>
                        </li>
                    })
                }
            </ul>
            <div className={Style.Pagination}>
                <button
                    onClick={() => {
                        if (pageNum <= 1) return
                        setPageNum(--pageNum)
                    }}
                >上一页
                </button>
                <p><span>{pageNum}</span>/<span>{Math.ceil(total / dataNum)}</span></p>
                <button
                    onClick={() => {
                        if (pageNum >= Math.ceil(total / dataNum)) return
                        setPageNum(++pageNum)
                    }}>下一页
                </button>
            </div>
        </div>
    )
}

export default Index;
