import React from "react";
import { useNavigate, useLocation } from "react-router-dom";
import Styles from "./index.module.scss";
import { ReactComponent as Mine } from "../../assets/mine.svg";
import { ReactComponent as Persons } from "../../assets/person.svg";
import { ReactComponent as Card } from "../../assets/card.svg";

const Index = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const pushNavigate = path => {
        if (!localStorage.getItem('userToken') && path === '/tutorials') {
            return navigate("/mine")
        }
        navigate(path)
    };

    return (
        <div className={Styles['NavWrap']}>
            <div className={`${Styles.navItem} ${location.pathname === '/mynft' ? Styles.active : ""}`} onClick={() => pushNavigate("/mynft")}><Card />我的 NFT</div>
            <div className={`${Styles.navItem} ${location.pathname === '/tutorials' ? Styles.active : ""}`} onClick={() => pushNavigate("/tutorials")}><Persons />我的邀请</div>
            <div className={`${Styles.navItem} ${location.pathname === '/mine' ? Styles.active : ""}`} onClick={() => pushNavigate("/mine")}><Mine />个人中心</div>
        </div>
    )
}

export default Index;
