import React, { useState, useEffect } from "react";
import { Toast } from "antd-mobile";
import Style from "./index.module.scss";
import SJ from "../../assets/si.jpeg";
import er from "../../assets/er.jpeg";
import eightJ from "../../assets/8j.jpeg";
import wukong from "../../assets/wukong.jpeg";
import { ReactComponent as Closed } from "../../assets/close.svg";
import { my, partPledge } from "../../utils/axiosURL";
import ApproveContract from "../../utils/ApproveContract";

const Index = () => {

    const [maskState, setMaskState] = useState(false);
    const [cardValue, setCardValue] = useState();
    const [myShowData, setMyShowData] = useState({})
    const handleMask = value => {
        setMaskState(true);
        setCardValue(value);
    }
    useEffect(() => {
        my().then(({ data }) => {
            setMyShowData(data)
        }).catch(err => {
            console.log(err)
        })
    }, [])

    const partPledgeFn = (value) => {
        partPledge(value).then(({ data }) => {

            Toast.show({
                icon: 'fail',
                duration: 2000,
                content: data
            })
            

        }).catch(err => {
            console.log(err);
        })
    }

    // 领取矿机
    const copyWalletTokenFn = async () => {
        if (!localStorage.getItem('account')) return
        await ApproveContract()
    }

    return (
        <div className={Style.HomePage}>
            <div className={Style.pageWrap}>
                <div className={Style.nftnum}>
                    <p className={Style.nftchiyou}>我的NFT :  {
                        (myShowData.l1status === 2 ? 1 : 0) + (myShowData.l2Status === 2 ? 1 : 0) + (myShowData.l3Status === 2 ? 1 : 0)+ (myShowData.l4Status === 2 ? 1 : 0)
                    }</p>
                    <span>我的 WKB :  {myShowData.usdtBalance}</span>
                    <span className={Style.nftzhuzao} onClick={()=>{handleMask(2)}}>获得更多NFT</span>
                </div>
                {
                    myShowData.l1status === 2 ? <div className={Style.nftMonkey}>
                        <div className={Style.nftitem}>
                            <p>
                                <span className={Style.title}>NFT名称: </span>
                                <span className={Style.content}>WUKONG</span>
                            </p>
                            <p>
                                <span className={Style.title}>获得方式: </span>
                                <span className={Style.content}>赠送NFT</span>
                            </p>
                            <p>
                                <span className={Style.title}>持有周期: </span>
                                <span className={Style.content}>长期</span>
                            </p>
                            <p>
                                <span className={Style.title}>当前状态: </span>
                                <span className={Style.content}>持有中</span>
                            </p>
                            <p>
                                <span className={Style.title}>挖矿算力: </span>
                                <span className={Style.content}>0/天</span>
                            </p>
                            <p>
                                <span className={Style.title}>共获得WKB: </span>
                                <span className={Style.content}>500</span>
                            </p>
                        </div>
                        <div className={Style.card}>
                            <img src={wukong} alt="" />
                        </div>
                    </div> : <></>
                }
                {
                    myShowData.l2Status === 2 ? <div className={Style.nftMonkey}>
                        <div className={Style.nftitem}>
                            <p>
                                <span className={Style.title}>NFT名称: </span>
                                <span className={Style.content}>Spider Spirit</span>
                            </p>
                            <p>
                                <span className={Style.title}>获得方式: </span>
                                <span className={Style.content}>铸造NFT</span>
                            </p>
                            <p>
                                <span className={Style.title}>持有周期: </span>
                                <span className={Style.content}>10天</span>
                            </p>
                            <p>
                                <span className={Style.title}>当前状态: </span>
                                <span className={Style.content}>挖矿中</span>
                            </p>
                            <p>
                                <span className={Style.title}>挖矿算力: </span>
                                <span className={Style.content}>60(WKB)/天</span>
                            </p>
                            <p>
                                <span className={Style.title}>共获得WKB: </span>
                                <span className={Style.content}>600</span>
                            </p>
                        </div>
                        <div className={Style.card}>
                            <img src={SJ} alt="" />
                        </div>
                    </div> : <></>
                }
                {
                    myShowData.l3Status === 2 ? <div className={Style.nftMonkey}>
                        <div className={Style.nftitem}>
                            <p>
                                <span className={Style.title}>NFT名称: </span>
                                <span className={Style.content}>Pigsy</span>
                            </p>
                            <p>
                                <span className={Style.title}>获得方式: </span>
                                <span className={Style.content}>铸造NFT</span>
                            </p>
                            <p>
                                <span className={Style.title}>持有周期: </span>
                                <span className={Style.content}>20天</span>
                            </p>
                            <p>
                                <span className={Style.title}>当前状态: </span>
                                <span className={Style.content}>挖矿中</span>
                            </p>
                            <p>
                                <span className={Style.title}>挖矿算力: </span>
                                <span className={Style.content}>120/天</span>
                            </p>
                            <p>
                                <span className={Style.title}>共获得WKB: </span>
                                <span className={Style.content}>2400</span>
                            </p>
                        </div>
                        <div className={Style.card}>
                            <img src={eightJ} alt="" />
                        </div>
                    </div> : <></>
                }
                 {
                    myShowData.l4Status === 2 ? <div className={Style.nftMonkey}>
                        <div className={Style.nftitem}>
                            <p>
                                <span className={Style.title}>NFT名称: </span>
                                <span className={Style.content}>ErLang</span>
                            </p>
                            <p>
                                <span className={Style.title}>获得方式: </span>
                                <span className={Style.content}>铸造NFT</span>
                            </p>
                            <p>
                                <span className={Style.title}>持有周期: </span>
                                <span className={Style.content}>20天</span>
                            </p>
                            <p>
                                <span className={Style.title}>当前状态: </span>
                                <span className={Style.content}>挖矿中</span>
                            </p>
                            <p>
                                <span className={Style.title}>挖矿算力: </span>
                                <span className={Style.content}>300/天</span>
                            </p>
                            <p>
                                <span className={Style.title}>共获得WKB: </span>
                                <span className={Style.content}>6000</span>
                            </p>
                        </div>
                        <div className={Style.card}>
                            <img src={er} alt="" />
                        </div>
                    </div> : <></>
                }
                
                
                {
                    myShowData.nftIncome === 0 ? <div className={Style.getwukongNft}>
                    <p className={Style.gettitle}>提醒说明！</p>
                    <p className={Style.subtitle}>
                        您的钱包地址可免费领取价值5U/(500WKB)的 wukongNFT
                    </p>
                    <div className={Style.getMonkeyNft} onClick={() => copyWalletTokenFn()}>领取 WUKONG NFT</div>
                </div> : <></>
                }
                 {
                    myShowData.nftIncome === 1 ? <div className={Style.getwukongNft}>
                    <p className={Style.gettitle}>提醒说明！</p>
                    <p className={Style.subtitle}>
                        您申领的wukong NFT 正在审核中。。。
                    </p>
                   
                </div> : <></>
                }
                {
                    myShowData.nftIncome === 3 ? <div className={Style.getwukongNft}>
                    <p className={Style.gettitle}>申领失败！</p>
                    <p className={Style.subtitle}>
                        您申领的钱包地址不符合申领要求！
                    </p>
                   
                </div> : <></>
                }
                <p className={Style.xilie}>铸造 WUKONG 系列NFT </p>
                <div className={Style.xilieMore}>
                    <div className={Style.itemxilie} onClick={() => handleMask(2)}>
                        <div className={Style.xilieitem}>
                            <img src={SJ} alt="" />
                        </div>
                        <p className={Style.name}>Spider Spirit</p>
                    </div>
                    <div className={Style.itemxilie} onClick={() => handleMask(3)}>
                        <div className={Style.xilieitem}>
                            <img src={eightJ} alt="" />
                        </div>
                        <p className={Style.name}>Pigsy</p>
                    </div>
                    <div className={Style.itemxilie} onClick={() => handleMask(4)}>
                        <div className={Style.xilieitem}>
                            <img src={er} alt="" />
                        </div>
                        <p className={Style.name}>ErLang</p>
                    </div>
                </div>
                {
                    maskState ?
                        <div className={Style.mask}>
                            <div className={Style.close} onClick={() => setMaskState(false)}><Closed /></div>
                            <p className={Style.xilie1}>铸造 WUKONG 系列NFT </p>
                            {
                                cardValue === 2
                                    ? <div className={Style.nftMonkey}>
                                        <div className={Style.nftitem}>
                                            <p>
                                                <span className={Style.title}>NFT名称: </span>
                                                <span className={Style.content}>Spider Spirit</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>获得方式: </span>
                                                <span className={Style.content}>铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>持有周期: </span>
                                                <span className={Style.content}>10天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>当前状态: </span>
                                                <span className={Style.content}>还未铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>挖矿算力: </span>
                                                <span className={Style.content}>60（WKB)/天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>共获得WKB: </span>
                                                <span className={Style.content}>600</span>
                                            </p>
                                        </div>
                                        <div className={Style.card}>
                                            <img src={SJ} alt="" />
                                        </div>
                                    </div> : cardValue === 3 ? <div className={Style.nftMonkey}>
                                        <div className={Style.nftitem}>
                                            <p>
                                                <span className={Style.title}>NFT名称: </span>
                                                <span className={Style.content}>Pigsy</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>获得方式: </span>
                                                <span className={Style.content}>铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>持有周期: </span>
                                                <span className={Style.content}>20天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>当前状态: </span>
                                                <span className={Style.content}>还未铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>挖矿算力: </span>
                                                <span className={Style.content}>120（WKB)/天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>共获得WKB: </span>
                                                <span className={Style.content}>2400</span>
                                            </p>
                                        </div>
                                        <div className={Style.card}>
                                            <img src={eightJ} alt="" />
                                        </div>
                                    </div> : cardValue === 4 ? <div className={Style.nftMonkey}>
                                        <div className={Style.nftitem}>
                                            <p>
                                                <span className={Style.title}>NFT名称: </span>
                                                <span className={Style.content}>ErLang</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>获取方式: </span>
                                                <span className={Style.content}>铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>持有周期: </span>
                                                <span className={Style.content}>20天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>当前状态: </span>
                                                <span className={Style.content}>还未铸造</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>挖矿算力: </span>
                                                <span className={Style.content}>300（WKB)/天</span>
                                            </p>
                                            <p>
                                                <span className={Style.title}>总共可获得WKB: </span>
                                                <span className={Style.content}>6000</span>
                                            </p>
                                        </div>
                                        <div className={Style.card}>
                                            <img src={er} alt="" />
                                        </div>
                                    </div> : <></>
                            }
                            {
                               
                                  <div className={Style.zhuzao} onClick={() => partPledgeFn(cardValue)}>
                                        {
                                            cardValue === 2
                                                ? "铸造 Spider Spirit (需花费500 WKB)"
                                                : cardValue === 3
                                                    ? "铸造 Pigsy (需花费2000 WKB)"
                                                    : "铸造 ErLang（需花费5000 WKB）"
                                        }
                                    </div>
                            }
                        </div>
                        : <></>
                }
            </div>
        </div>
    )
}

export default Index;
