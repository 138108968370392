import React from "react";
import { useRoutes } from "react-router-dom";
import routers from "./router/index";
import "./style/reset.css";

function App() {

  const routes = useRoutes(routers);

  return (
    <div className="App">
      {routes}
    </div>
  );
}

export default App;