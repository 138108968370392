import Home from "../view/Home/index";
import Tutorials from "../view/Tutorials/index";
import Mine from "../view/Mine/index";
import Layout from "../view/Layout/index";
import Mynft from "../view/Mynft";
import Beneficiay from "../view/beneficiay";
import Withdraw from "../view/withdraw";

const routers = [
    {
        path: "/",
        element: <Layout />,
        children: [
            {
                path: "/",
                element: <Home />
            },
            {
                path: "/tutorials",
                element: <Tutorials />
            },
            {
                path: "/mine",
                element: <Mine />
            },
            {
                path: "/mynft",
                element: <Mynft />
            },
            {
                path: "/beneficiay",
                element: <Beneficiay />
            },
            {
                path: "/withdraw",
                element: <Withdraw />
            }
        ]
    }
];

export default routers;
