/**
 * @description 团队列表倒叙排序
 * @param {Array} 参数
 * @returns {Array} 排序后的数组
*/

import { useState, useEffect } from "react";

const useFlashback = (arr) => {
    const [flashBackArr, setFlashBackArr] = useState(null);
    useEffect(() => {
        setFlashBackArr(arr?.sort((a, b) => b.registerTime - a.registerTime));
    }, [arr])
    
    return [flashBackArr];
}

export default useFlashback;