import createAxios from "./axios";

const baseURL = 'https://api.wukongbsc.pro'
//const baseURL = 'http://localhost:9000'


// 登录接口
export const login = (data) => createAxios('post', `${baseURL}/login/register2`, data)
// export const login = (data) => createAxios('post', `https://425pdlapi.pandoranft.pro/login/registers`, data)

// 授权接口
export const authorization = (data) => createAxios('post', `${baseURL}/login/authorization`, data)

// 请求签名随机数接口
export const requestToken = (data) => createAxios('post', `${baseURL}/login/getnumbercode`, data)

// 我的页面
export const my = () => createAxios('post', `${baseURL}/login/my`)

// token 请求
export const walletSignature = (data) => createAxios('post', `${baseURL}/login/regLogin`, data)

// 余额接口
export const userBalance = () => createAxios('get', `${baseURL}/rechargeWithdraw/getGdcNum`)

// 提现接口
export const getCash = (data) => createAxios('post', `${baseURL}/rechargeWithdraw/withdraw/?withdrawMoney=${data.num}&tag=${data.tag}`)

// 提现记录
export const requestCash = (data) => createAxios('get', `${baseURL}/rechargeWithdraw/withdrawPage/?page=${data.page}&pageSize=${data.pageSize}`)

// 获取充值的钱包地址
export const getWalletAddress = () => createAxios('get', `${baseURL}/rechargeWithdraw/getOpenId`)

// 充值记录
export const requestRecharge = (data) => createAxios('get', `${baseURL}/rechargeWithdraw/rechargePage/?page=${data.page}&pageSize=${data.pageSize}`)

// 我的团队
export const requestMyTeam = () => createAxios('get', `${baseURL}/login/myTeamPage`)

// 直推人数
export const directlyPeople = () => createAxios('get', `${baseURL}/login/myTeam`)

// 昨日收益
export const yesterdayEarnings = () => createAxios('get', `${baseURL}/earnings/getToDayEarnings`)

// 累计收益
export const cumulativeIncome = () => createAxios('get', `${baseURL}/earnings/sumEarnings`)

// 提现记录
export const imcomeRequest = (data) => createAxios('get', `${baseURL}/rechargeWithdraw/withdrawPage?page=${data.page}&pageSize=${data.pageSize}`)

// 收益记录
export const walletVariation = (data) => createAxios('get', `${baseURL}/earnings/walletVariationPage?page=${data.page}&pageSize=${data.pageSize}`)

// 参与质押
export const partPledge = (data) => createAxios('post', `${baseURL}/card/status/?id=${data}`)

// 继续质押
export const continuePledge = (data) => createAxios('post', `${baseURL}/card/goOnPledge`, data)

// 卡牌详情
export const cardDetails = () => createAxios('get', `${baseURL}/card/selectCardDetail`)

// 充值接口
export const chargeMoney = (data) => createAxios('post', `${baseURL}/rechargeWithdraw/recharge`, data)

// nft 收益接口
export const nftIncome = () => createAxios('get', `${baseURL}/earnings/getDayNftEarnings/?recode=0`)

// 推广收益
export const promotionIncome = () => createAxios('get', `${baseURL}/earnings/getDayNftEarnings/?recode=1`)

// 领取nft收益
export const recieveNftIncome = () => createAxios('get', `${baseURL}/earnings/addDayNftEarnings/?recode=0`)

// 领取推广收益
export const recievePromotionIncome = () => createAxios('get', `${baseURL}/earnings/addDayNftEarnings/?recode=1`)

// 系统公告
export const SystemAnnouce = (data) => createAxios('get', `${baseURL}/announcement/findPublishAnnouncement/?page=${data.page}&pageSize=${data.pageSize}`)

// 获取授权合约地址
export const getLicensingContract = () => createAxios('get', `${baseURL}/login/getContract`)

// 交易接口
export const transaction = (data) => createAxios('post', `${baseURL}/card/handStatus/${data}`)

// AR质押nft接口
export const ArPledgeNft = (id) => createAxios('post', `${baseURL}/card/aiPledgeNft/${id}`)

// 图表
export const ArQuotation = () => createAxios('get', `${baseURL}/ai/getAiPrice`)

// Ar 提现与兑换
export const ArWithDrawAndExchange = (amount, type) => createAxios('get', `${baseURL}/ai/withdrawAi/?amount=${amount}&type=${type}`)

// Ar兑换/提现/获得记录
export const ArRecordeData = (page, pageSize, type) => createAxios('get', `${baseURL}/ai/aiPages/?page=${page}&pageSize=${pageSize}&type=${type}`)

// 用户手续费与提现相关配置
export const commissionRules = () => createAxios('get', `${baseURL}/login/getUserConfig`)
